import React from 'react';
import {Button, Card, Col, Form, InputGroup, Row, NavDropdown} from "react-bootstrap";
import DataTable from 'react-data-table-component'
import {customStyles} from "../../assets/js/customTable";
const SubAdminPage = () => {
    const [filterText, setFilterText] = React.useState('')

    const data = [
        {
            id: 1,
            name: 'John Doe',
            contact: '9876543210',
            email: 'john@gmail.com',
            trade: 'Trade Show 1',
            companyName: 'Active',
        },
        {
            id: 2,
            name: 'John Doe',
            contact: '9876543210',
            email: 'john@gmail.com',
            trade: 'Trade Show 1',
            companyName: 'Active',
        },
    ]
    const columns = [
        {
            name: 'Sub Admin Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.contact,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Location',
            selector: (row) => row.trade,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => <span className="text-success px-3 py-1 rounded-pill" style={{backgroundColor: '#32936F29'}}>{row.companyName}</span>,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <i className="fa-light fa-eye text-primary s-14 cursor-pointer me-2"/>
                        <i className="fa-light fa-edit text-primary s-14 cursor-pointer me-2"/>
                        <i className="fa-light fa-trash-alt text-danger s-14 cursor-pointer"/>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const filteredItems = data.filter(
        (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    )
    return (
        <>
            <Card>
                <Card.Body>
                    <Row className="align-items-center justify-content-end mb-3">
                        <Col lg={3}>
                            <InputGroup className="m-0">
                                <InputGroup.Text className="bg-transparent">
                                    <i className="fa-light fa-search d-block s-15" />
                                </InputGroup.Text>
                                <Form.Control
                                    type="search"
                                    placeholder="Search"
                                    className="bg-transparent shadow-none s-14"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Button variant="light" className="shadow-none s-14">
                                Download CSV
                            </Button>
                        </Col>
                        <Col lg={2}>
                            <Button variant="primary" className="shadow-none s-14 text-white w-100">
                                <i className="fa-light fa-plus d-inline-block s-15" />
                                &nbsp;&nbsp;
                                Add Sub Admin
                            </Button>
                        </Col>
                    </Row>
                    <DataTable
                        fixedHeaderScrollHeight="500px"
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        pagination={true}
                        fixedHeader={true}
                        // dense={true}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default SubAdminPage;