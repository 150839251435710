import React from 'react';
import SubAdminPage from "../components/subAdmin";

const SubAdmin = () => {
    return (
        <>
            <SubAdminPage />
        </>
    );
};

export default SubAdmin;