import React, {useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import logo from '../../assets/images/logo.svg'
import user from '../../assets/images/user.svg'
import {Link, Outlet} from "react-router-dom";
import Header from "./Header";

const Layout = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    return (
        <div>
            <Row className="g-0">
                <Col className="border px-3 bg-white position-fixed top-0 start-0" style={{maxWidth: 295, height: '100dvh'}}>
                    <img src={logo} alt="circle-care-app" height={100} className="d-block mx-auto my-4" />
                    <h6 className="fw-600 s-12 text-secondary">OVERVIEW</h6>
                    <Row className="align-items-center p-2 ms-2 mb-2">
                        <Col xs="auto">
                            <i className="fa-light fa-grid-2 d-block s-22"></i>
                        </Col>
                        <Col>
                            <Link to="/" className="fw-400 s-15 text-menu mb-0 text-decoration-none">Dashboard</Link>
                        </Col>
                    </Row>
                    <Row className="align-items-center p-2 ms-2 mb-2">
                        <Col xs="auto">
                            <i className="fa-light fa-users d-block s-22"></i>
                        </Col>
                        <Col>
                            <Link to="/sub-admin" className="fw-400 s-15 text-menu text-decoration-none mb-0">Sub Admins</Link>
                        </Col>
                    </Row>
                    <Row className="align-items-center p-2 ms-2 mb-2">
                        <Col xs="auto">
                            <i className="fa-light fa-users d-block s-22"></i>
                        </Col>
                        <Col>
                            <Link to="users" className="fw-400 s-15 text-menu text-decoration-none mb-0">End Users</Link>
                        </Col>
                    </Row>
                    <h6 className="fw-600 s-12 text-secondary">THE APP</h6>
                    <div className="">
                        <Row className="align-items-center p-2 ms-2 mb-2" role="button" onClick={() => setOpen(!open)}>
                            <Col xs="auto">
                                <i className="fa-light fa-folder d-block s-22"/>
                            </Col>
                            <Col>
                                <h6 className="fw-400 s-15 text-menu mb-0">Questionnaire</h6>
                            </Col>
                            <Col xs="auto">
                                <i className={`fa-light fa-chevron-down d-block s-20 ${open ? 'fa-rotate-90' : ''}`}/>
                            </Col>
                        </Row>
                        <Collapse in={open}>
                            <Row className="align-items-center p-2 ms-2 mb-2">
                                <Col xs="auto">
                                    <i className="fa-light fa-grid-2 d-block s-22"></i>
                                </Col>
                                <Col>
                                    <h6 className="fw-400 s-15 text-menu mb-0">Dashboard</h6>
                                </Col>
                            </Row>
                        </Collapse>
                    </div>
                    <div className="">
                        <Row className="align-items-center p-2 ms-2 mb-2" role="button" onClick={() => setOpen2(!open2)}>
                            <Col xs="auto">
                                <i className="fa-light fa-gem d-block s-22"/>
                            </Col>
                            <Col>
                                <h6 className="fw-400 s-15 text-menu mb-0">Articles / Videos</h6>
                            </Col>
                            <Col xs="auto">
                                <i className={`fa-light fa-chevron-down d-block s-20 ${open2 ? 'fa-rotate-90' : ''}`}/>
                            </Col>
                        </Row>
                        <Collapse in={open2}>
                            <Row className="align-items-center p-2 ms-2 mb-2">
                                <Col xs="auto">
                                    <i className="fa-light fa-grid-2 d-block s-22"></i>
                                </Col>
                                <Col>
                                    <h6 className="fw-400 s-15 text-menu mb-0">Dashboard</h6>
                                </Col>
                            </Row>
                        </Collapse>
                    </div>
                </Col>
                <Col className="position-relative" style={{marginLeft: 295}}>
                    <Header />
                    <div className="p-3">
                        <Outlet />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Layout;