import React from 'react';
import SignUpPage from "../components/auth/SignUpPage";

const SignUp = () => {
    return (
        <>
            <SignUpPage />
        </>
    );
};

export default SignUp;