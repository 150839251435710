import React from 'react';
import {Col, Row} from "react-bootstrap";

const Header = () => {
    return (
        <>
            <div className="bg-white position-sticky top-0">
                <Row className="g-0 p-3">
                    <Col xs="auto">
                        <h6 className="m-0 s-20">Dashboard</h6>
                    </Col>
                </Row>
                <hr className="m-0"/>
                <Row className="gx-2 p-1 px-3 align-items-center">
                    <Col xs="auto">
                        <i className="fa-solid fa-house d-block s-12 text-success"/>
                    </Col>
                    <Col xs="auto">
                        <p className="m-0 fw-400 s-12 text-muted">/</p>
                    </Col>
                    <Col xs="auto">
                        <p className="m-0 fw-400 s-12 text-muted">Dashboard</p>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Header;