import React from 'react';
import RecoverPage from "../components/auth/RecoverPage";
import VerifyPage from "../components/auth/VerifyPage";

const Verify = () => {
    return (
        <>
            <VerifyPage />
        </>
    );
};

export default Verify;