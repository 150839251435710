import React from 'react';
import CreatePasswordPage from "../components/auth/CreatePasswordPage";

const CreatePassword = () => {
    return (
        <>
            <CreatePasswordPage />
        </>
    );
};

export default CreatePassword;