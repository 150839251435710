import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import AuthLayout from "../components/auth/AuthLayout";
import Layout from "../components/layout";

import Login from "../pages/Login";
import Recover from "../pages/Recover";
import Verify from "../pages/Verify";
import CreatePassword from "../pages/CreatePassword";
import SignUp from "../pages/SignUp";
import Home from "../pages/Home";
import SubAdmin from "../pages/SubAdmin";
import {useSelector} from "react-redux";

const AppRoute = () => {
    const navigate = useNavigate()
    const {token} = useSelector(state => state.auth);

    const checkToken = () => {
        if(token !== null) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        checkToken() ? navigate('/') : navigate('/auth/login')
    }, [])

    console.log('sd', token, 'xx', checkToken())
    return (
        <>
            <Routes>
                {!checkToken() ? (
                    <Route path="/auth" element={<AuthLayout />}>
                        <Route index element={<Navigate to={{pathname: "/auth/login"}}/>} />
                        <Route path="login" element={<Login />} />
                        <Route path="recover" element={<Recover />} />
                        <Route path="verify" element={<Verify />} />
                        <Route path="create-password" element={<CreatePassword />} />
                        <Route path="sign-up" element={<SignUp />} />
                    </Route>
                ) : (
                    <Route path="/" element={<Layout />}>
                        <Route index element={<>
                            {!checkToken() ? <Navigate to={{pathname: "/auth/login"}}/> : <Home />}
                        </>} />
                        <Route path="sub-admin" element={<SubAdmin />} />
                    </Route>
                )}
            </Routes>
        </>
    );
};

export default AppRoute;