import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import user from "../../assets/images/user.svg";
import LineChart from "./LineChart";

const DashboardPage = () => {
    return (
        <>
            <Row>
                    <Col lg={6}>
                        <Card className="border-0 shadow-sm rounded-4 mb-4">
                            <Card.Body>
                                <h6 className="mb-3 fw-00 s-13 text-muted">
                                    Overview
                                </h6>
                                <Row className="justify-content-around">
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            10,234
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total Users
                                        </p>
                                    </Col>
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            1,203
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total xxxx
                                        </p>
                                    </Col>
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            15,988
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total xxxxx
                                        </p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border-0 shadow-sm rounded-4 mb-4">
                            <Card.Body>
                                <h6 className="mb-3 fw-00 s-13 text-muted">
                                    App Revenue Overview
                                </h6>
                                <Row className="justify-content-around">
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            10,234
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total Users
                                        </p>
                                    </Col>
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            1,203
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total xxxx
                                        </p>
                                    </Col>
                                    <Col xs="auto">
                                        <h1 className="mb-1 s-24 fw-800 text-info text-center">
                                            15,988
                                        </h1>
                                        <p className="m-0 fw-500 s-14 text-muted text-center">
                                            Total xxxxx
                                        </p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border-0 shadow-sm rounded-4 mb-4">
                            <Card.Body>
                                <h6 className="mb-3 fw-00 s-13 text-muted">
                                    Registered users in a week
                                </h6>
                                <LineChart />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="border-0 shadow-sm rounded-4 mb-4">
                            <Card.Body>
                                <h6 className="mb-3 fw-00 s-13 text-muted">
                                    Active Users
                                </h6>
                                <Row className="gx-2 align-items-center mb-2">
                                    <Col xs="auto">
                                        <img src={user} alt="circle-care-app" width={45} height={45} className="rounded-circle"/>
                                    </Col>
                                    <Col xs="auto">
                                        <p className="m-0 fw-400 s-16 text-dark">
                                            Stephen Carl
                                        </p>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <div className="rounded-circle bg-success" style={{height: 13, width: 13}}/>
                                    </Col>
                                </Row>
                                <Row className="gx-2 align-items-center mb-2">
                                    <Col xs="auto">
                                        <img src={user} alt="circle-care-app" width={45} height={45} className="rounded-circle"/>
                                    </Col>
                                    <Col xs="auto">
                                        <p className="m-0 fw-400 s-16 text-dark">
                                            Stephen Carl
                                        </p>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <div className="rounded-circle bg-success" style={{height: 13, width: 13}}/>
                                    </Col>
                                </Row>
                                <Row className="gx-2 align-items-center mb-2">
                                    <Col xs="auto">
                                        <img src={user} alt="circle-care-app" width={45} height={45} className="rounded-circle"/>
                                    </Col>
                                    <Col xs="auto">
                                        <p className="m-0 fw-400 s-16 text-dark">
                                            Stephen Carl
                                        </p>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <div className="rounded-circle bg-success" style={{height: 13, width: 13}}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
        </>
    );
};

export default DashboardPage;