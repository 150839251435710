import { createSlice } from '@reduxjs/toolkit'
import {api} from "../services/api";

const initialState = {
    user: null,
    token: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeLang: (state, { payload }) => {
            state.lang = payload
        },

    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.login.matchFulfilled, (state, { payload }) => {
                    let data = payload
                    console.log('sdsd===', data)
                    if(data?.success){
                        // state.user = data?.user
                        state.token = data?.refresh_token
                    }
                }
            )
            // .addMatcher(
            //     api.endpoints.addCouponCode.matchFulfilled, (state, { payload }) => {
            //         let data = payload
            //         if(data?.status === 200){
            //             let total = parseFloat(state?.subTotal) - parseFloat(data?.subtotal) + parseFloat(state.tax)
            //             state.total = parseFloat(total).toFixed(2)
            //             state.discount = data?.d_percent
            //         }
            //     }
            // )

    },
})

// Action creators are generated for each case reducer function
export const { increment, decrement , addToCart, changeLang} = authSlice.actions

export default authSlice.reducer