import React from 'react';
import RecoverPage from "../components/auth/RecoverPage";

const Recover = () => {
    return (
        <>
            <RecoverPage />
        </>
    );
};

export default Recover;